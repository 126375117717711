import { useIntl } from "react-intl";

import GENERAL_MESSAGES from "./foodAccessLegend";
import LEVEL_MESSAGES from "./levels";
import CATEGORY_MESSAGES from "./categories";
import STATISTIC_MESSAGES from "./statistics";
import DEMOGRAPHIC_MESSAGES from "./demographics";
import UNIT_MESSAGES from "./units";

const MESSAGES = {
  ...GENERAL_MESSAGES,
  ...LEVEL_MESSAGES,
  ...CATEGORY_MESSAGES,
  ...STATISTIC_MESSAGES,
  ...DEMOGRAPHIC_MESSAGES,
  ...UNIT_MESSAGES,
};

function useHumanize() {
  const intl = useIntl();
  return (descriptor, values) => {
    if (!MESSAGES.hasOwnProperty(descriptor)) {
      if (process.env.NODE_ENV !== "production") {
        throw new Error(`No message configured for '${descriptor}'`);
      }
      console.error(`No message configured for '${descriptor}'`);
      return descriptor;
    }
    return intl.formatMessage(MESSAGES[descriptor], values);
  };
}

function useFormatNumber() {
  const intl = useIntl();
  return (value, selection) => {
    const { statistic, category, unit } = selection || {};

    const shouldRound =
      statistic === "ahrq_ses" || statistic === "person_one_more_room";

    if (!unit) return;

    if (unit.endsWith("percent")) {
      return intl.formatMessage(
        {
          defaultMessage: "{value, number, ::percent .0}",
        },
        { value }
      );
    }

    if (unit === "year") {
      return value;
    }

    if (unit.endsWith("dollars")) {
      return intl.formatNumber(value, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    if (category === "food_access") {
      return intl.formatMessage(MESSAGES[value], {
        value,
      });
    }

    if (!value) {
      return "0";
    }

    return intl.formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: shouldRound
        ? 0
        : value
            .toString()
            .split("")
            .findIndex((i) => i !== "." && i !== "0") + 2,
    });
  };
}

export default useHumanize;
export { useFormatNumber };
