import React from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import colors from "../colors";
import { isCredentialsRoute, isHomeRoute } from "../utils";
import TopLevelErrorBoundary from "../errors/TopLevelErrorBoundary";
import bgHomePage from "../images/bg-home-page.svg";
import lightsSign from "../images/lights-sign.svg";

const { Content, Header } = Layout;

function ResponsiveLayout({ header, content }) {
  const location = useLocation();

  const hasCrendentialsRoute = isCredentialsRoute(location?.pathname);
  const hasHomeRoute = isHomeRoute(location?.pathname);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: hasHomeRoute || hasCrendentialsRoute ? "relative" : null,
        backgroundColor: hasHomeRoute || hasCrendentialsRoute ? colors.darkBlue : null,
      }}
    >
      {hasHomeRoute ? (
        <img
          src={bgHomePage}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="bgHomePage"
        />
      ): null}

      {hasCrendentialsRoute && (
        <img
          src={lightsSign}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="bgCredentialsPage"
        />
      )}

      <Header
        style={{
          zIndex: 1001,
          backgroundColor: hasCrendentialsRoute || hasHomeRoute
            ? "transparent"
            : colors.navyBlue,
          position: hasHomeRoute ? null : "relative",
          height: hasHomeRoute || hasCrendentialsRoute ? "auto" : null,
          width: "100%",
          padding: hasHomeRoute ? 0 : null,
        }}
      >
        {header}
      </Header>

      <Content>
        <TopLevelErrorBoundary>{content}</TopLevelErrorBoundary>
      </Content>
    </Layout>
  );
}

export default ResponsiveLayout;
