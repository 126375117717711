import { getErrorAPIMessage } from "../../errors/getErrorAPIMessage";
import { api } from "../axios";
import qs from "qs";

export const login = async ({ email, password }) => {
  try {
    const data = qs.stringify({ username: email, password });
    const response = await api.post("/auth/login", data);
    return {
      accessToken: response?.data?.access_token,
    };
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};

export const validateToken = async (accessToken) => {
  try {
    const response = await api.get(`/auth/validate-token/${accessToken}`);
    return response?.data;
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};
