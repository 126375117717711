export const handleDownloadBlob = (data, filename) => {
  const blob = new Blob([data]);
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute("download", "statistics.zip");

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
