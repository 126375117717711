import React from "react";
import { Button, Menu, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import _entries from "lodash/fp/entries";
import _groupBy from "lodash/fp/groupBy";
import _map from "lodash/fp/map";
import _mapValues from "lodash/fp/mapValues";
import _pipe from "lodash/fp/pipe";
import _remove from "lodash/fp/remove";
import _sortBy from "lodash/fp/sortBy";
import _uniqBy from "lodash/fp/uniqBy";

import { useMeasures } from "../../cube";
import useHumanize from "../../humanize";
import useDescription from "../../descriptions";

import { selectionToRoute } from "./route";

import styles from "./StatisticsMenu.module.css";
import { REMOVED_STATISTICS } from "../../constants";

const { SubMenu } = Menu;

function StatisticsMenu({ selection }) {
  const measures = _remove((measure) => measure.category === null)(
    useMeasures()
  );

  const humanize = useHumanize();
  let categories = getCategories(measures);

  const ageOrder = [
    "age_median",
    "age_lt_5",
    "age_lt_18",
    "age_5_to_9",
    "age_10_to_14",
    "age_15_to_17",
    "age_18_to_29",
    "age_30_to_44",
    "age_45_to_64",
    "age_50_to_64",
    "age_gte_65",
    "age_gte_80",
  ];

  const totalsOrder = [
    "total_population",
    "population_density",
    "foreign_born_population",
  ];

  const educationOrder = [
    "hs_diploma_or_less",
    "graduate_degree",
    "bachelor_degree_or_higher",
  ];

  const obesityOrder = [
    "obesity_overweight",
    "obesity_total_obesity",
    "obesity_class_1_obesity",
    "obesity_class_2_obesity",
    "obesity_class_3_obesity",
  ];

  const healthCategoryOrder = [
    "null",
    "cdc",
    "ckd",
    "diabetes",
    "htn",
    "mi",
    "obesity",
    "stroke",
    "hi",
  ];

  const demographicsOrder = ["null", "age", "ethnicity", "race", "sex"];

  return (
    <Menu mode="inline" style={{ border: "none" }}>
      {_map(([category, statisticGroups]) => {
        if (category === "demographics") {
          const statisticGroupsKeys = Object.keys(statisticGroups).sort(
            (a, b) => {
              const aIndex = demographicsOrder.indexOf(a);
              const bIndex = demographicsOrder.indexOf(b);
              return aIndex - bIndex;
            }
          );

          const newStatisticGroups = {};
          statisticGroupsKeys.forEach((key) => {
            newStatisticGroups[key] = statisticGroups[key];
          });

          statisticGroups = newStatisticGroups;
        }

        if (category === "health") {
          const statisticGroupsKeys = Object.keys(statisticGroups).sort(
            (a, b) => {
              const aIndex = healthCategoryOrder.indexOf(a);
              const bIndex = healthCategoryOrder.indexOf(b);
              return aIndex - bIndex;
            }
          );

          const newStatisticGroups = {};
          statisticGroupsKeys.forEach((key) => {
            newStatisticGroups[key] = statisticGroups[key];
          });

          statisticGroups = newStatisticGroups;
        }

        return (
          <SubMenu key={category} title={humanize(category)}>
            {_map(([statisticGroup, statistics]) => {
              statisticGroup = parseNullKey(statisticGroup);

              console.log("statics", statisticGroup);

              if (statisticGroup === "obesity") {
                statistics = Object.values(statistics).sort((a, b) => {
                  const aIndex = obesityOrder.indexOf(a.statistic);
                  const bIndex = obesityOrder.indexOf(b.statistic);
                  return aIndex - bIndex;
                });
              }

              const humanizedStatisticGroup = humanize(
                Object.keys(statistics)[0]
              );

              if (category === "education") {
                statistics = Object.values(statistics).sort((a, b) => {
                  const aIndex = educationOrder.indexOf(a.statistic);
                  const bIndex = educationOrder.indexOf(b.statistic);
                  return aIndex - bIndex;
                });
              }

              if (category === "demographics" && statisticGroup === null) {
                statistics = Object.values(statistics).sort((a, b) => {
                  const aIndex = totalsOrder.indexOf(a.statistic);
                  const bIndex = totalsOrder.indexOf(b.statistic);
                  return aIndex - bIndex;
                });
              }

              if (category === "demographics" && statisticGroup === "age") {
                statistics = Object.values(statistics).sort((a, b) => {
                  const aIndex = ageOrder.indexOf(a.statistic);
                  const bIndex = ageOrder.indexOf(b.statistic);
                  return aIndex - bIndex;
                });
              }

              if (REMOVED_STATISTICS.includes(humanizedStatisticGroup)) return;

              return addSubMenu({
                shouldAdd: statisticGroup !== null,
                shouldAddPopover: statisticGroup === "cdc",
                key: statisticGroup,
                title:
                  statisticGroup !== null ? humanize(statisticGroup) : null,
                children: _map(({ statistic }) => {
                  const humanizedStatistic = humanize(statistic);

                  if (REMOVED_STATISTICS.includes(humanizedStatistic)) return;

                  return (
                    <Menu.Item
                      key={`${category}-${statistic}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      disabled={
                        !measures.some(
                          (m) =>
                            m.statistic === statistic &&
                            m.level === selection.level
                        )
                      }
                      className={styles.menuItem}
                    >
                      <Link
                        to={selectionToRoute({
                          category,
                          statistic,
                        })}
                        title={humanizedStatistic}
                        style={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {humanizedStatistic}
                      </Link>

                      <Popover
                        placement="right"
                        title={humanizedStatistic}
                        content={<Description statistic={statistic} />}
                        trigger="click"
                        overlayStyle={{
                          width: 300,
                          height: 600,
                          overflowY: "auto",
                        }}
                      >
                        <Button
                          type="default"
                          shape="circle"
                          onClick={(e) => e.stopPropagation()}
                          style={{ border: "none", background: "none" }}
                        >
                          <InfoCircleOutlined style={{ margin: 0 }} />
                        </Button>
                      </Popover>
                    </Menu.Item>
                  );
                })(statistics),
              });
            })(_entries(statisticGroups))}
          </SubMenu>
        );
      })(_entries(categories))}
    </Menu>
  );
}

export function moveCategoryToAnotherIndex(
  categories,
  fromCategory,
  toCategory,
  childCategory
) {
  const fromCategoryData = categories[fromCategory];
  const childCategoryData = fromCategoryData.null[childCategory];
  const newCategories = categories;

  delete newCategories[fromCategory].null[childCategory];

  newCategories[toCategory].null[childCategory] = childCategoryData;
  return newCategories;
}

export function getCategories(measures) {
  const categories = _pipe(
    _sortBy(["category", "statistic"]),
    // _remove((measure) => measure.category === "demographics"),
    _groupBy("category"),
    _mapValues(
      _pipe(
        _groupBy((measure) => {
          const statistic = measure.statistic;
          const category = measure.category;

          if (
            category === "demographics" &&
            (statistic.includes("age_") ||
              statistic.includes("sex_") ||
              statistic.includes("race_") ||
              statistic.includes("ethnicity_"))
          ) {
            return statistic.slice(0, statistic.indexOf("_"));
          }

          if (
            measure.category === "health" ||
            measure.statistic.includes("bb_") ||
            measure.statistic.includes("et_") ||
            measure.statistic.includes("hi_")
          ) {
            return measure.statistic.slice(0, measure.statistic.indexOf("_"));
          }

          return null;
        }),
        _mapValues(
          _pipe(
            _groupBy("statistic"),
            _mapValues(_pipe(_uniqBy(["category", "statistic"]), one))
          )
        )
      )
    )
  )(measures);

  return categories;
}

function one(items) {
  if (items.length !== 1) {
    throw new Error(
      `More than one item in items: ${_map(JSON.stringify)(items)}`
    );
  }
  return items[0];
}

function addSubMenu({ shouldAdd, shouldAddPopover, key, title, children }) {
  if (shouldAdd) {
    return (
      <SubMenu
        key={key}
        title={
          <CustomSubMenu
            shouldAddPopover={shouldAddPopover}
            statisticGroup={key}
            title={title}
          />
        }
      >
        {children}
      </SubMenu>
    );
  } else {
    return children;
  }
}

const CustomSubMenu = ({ shouldAddPopover, statisticGroup, title }) => {
  return (
    <article>
      <span>{title}</span>

      {shouldAddPopover && (
        <Popover
          placement="right"
          title={title}
          content={<Description statistic={statisticGroup} />}
          trigger="click"
          overlayStyle={{
            width: 300,
            height: 600,
            overflowY: "auto",
          }}
        >
          <Button
            type="default"
            shape="circle"
            onClick={(e) => e.stopPropagation()}
            style={{ border: "none", background: "none" }}
          >
            <InfoCircleOutlined style={{ margin: 0 }} />
          </Button>
        </Popover>
      )}
    </article>
  );
};

function parseNullKey(key) {
  if (key === "null") {
    return null;
  } else {
    return key;
  }
}

function Description({ statistic }) {
  return useDescription(statistic);
}

export default StatisticsMenu;
