import { useIntl, defineMessages } from "react-intl";
import ReactHtmlParser from "react-html-parser";

function useDescription(statistic) {
  const intl = useIntl();
  if (statistic === null) {
    return null;
  } else if (DESCRIPTIONS.hasOwnProperty(statistic)) {
    return (
      <div>{ReactHtmlParser(intl.formatMessage(DESCRIPTIONS[statistic]))}</div>
    );
  } else {
    throw new Error(`Missing description for statistic ${statistic}`);
  }
}

const DESCRIPTIONS = defineMessages({
  age_10_to_14: {
    id: "description.age_10_to_14",
    defaultMessage: `
      Percentage of population between ages 10-14
    `,
  },
  age_15_to_17: {
    id: "description.age_15_to_17",
    defaultMessage: `
      Percentage of population between ages 15-17
    `,
  },
  age_18_to_29: {
    id: "description.age_18_to_29",
    defaultMessage: `
      Percentage of population between ages 18-29
    `,
  },
  age_30_to_44: {
    id: "description.age_30_to_44",
    defaultMessage: `
      Percentage of population between ages 30-44
    `,
  },
  age_45_to_64: {
    id: "description.age_45_to_64",
    defaultMessage: `
      Percentage of population between ages 45-64
    `,
  },
  age_5_to_9: {
    id: "description.age_5_to_9",
    defaultMessage: `
      Percentage of population between ages 5-9
    `,
  },
  age_gte_65: {
    id: "description.age_gte_65",
    defaultMessage: `
      Percentage of population 65 and older
    `,
  },
  age_lt_5: {
    id: "description.age_lt_5",
    defaultMessage: `
      Percentage of population between ages 0-4
    `,
  },
  bachelor_degree_or_higher: {
    id: "description.bachelor_degree_or_higher",
    defaultMessage: `
      Percentage of population with a bachelor's degree or higher (ages 25 and over)
    `,
  },
  hs_diploma_or_less: {
    id: "description.hs_diploma_or_less",
    defaultMessage: `
      Percentage of population with only high school diploma or less (ages 25 and over)
    `,
  },
  age_median: {
    id: "description.age_median",
    defaultMessage: `
      Median age of total population
    `,
  },
  race_asian: {
    id: "description.race_asian",
    defaultMessage: `
      Percent of population self-identifying as Asian
    `,
  },
  race_black: {
    id: "description.race_black",
    defaultMessage: `
      Percent of population self-identifying as Black or African American
    `,
  },
  race_white: {
    id: "description.race_white",
    defaultMessage: `
      Percent of population self-identifying as White
    `,
  },
  sex_female: {
    id: "description.sex_female",
    defaultMessage: `
      Percentage of population that is female
    `,
  },
  sex_male: {
    id: "description.sex_male",
    defaultMessage: `
      Percentage of population that is male
    `,
  },
  ethnicity_hispanic: {
    id: "description.ethnicity_hispanic",
    defaultMessage: `
      Percent of population self identifying as Hispanic
    `,
  },
  ethnicity_non_hispanic: {
    id: "description.ethnicity_non_hispanic",
    defaultMessage: `
      Percentage of population that is Non-Hispanic
    `,
  },
  population_density: {
    id: "description.population_density",
    defaultMessage: `
      Population density (Census Tract)
    `,
  },
  foreign_born_population: {
    id: "description.foreign_born_population",
    defaultMessage: `
      Percentage of population that is foreign-born
    `,
  },
  age_lt_18: {
    id: "description.age_lt_18",
    defaultMessage: `
      Percentage of population between 0-17
    `,
  },
  race_more_than_one: {
    id: "description.race_more_than_one",
    defaultMessage: `
      Percent of population self-identifying as more than one race
    `,
  },
  race_native_pacific_islander: {
    id: "description.race_native_pacific_islander",
    defaultMessage: `
      Percent of population self-identifying as Native Hawaiian or Other Pacific Islander
    `,
  },
  race_american_indian_alaska_native: {
    id: "description.race_american_indian_alaska_native",
    defaultMessage: `
      Percent of population self-identifying as American Indian or Alaskan Native
    `,
  },
  // economy
  below_poverty: {
    id: "description.below_poverty",
    defaultMessage: `
      Percentage of population with an income to poverty ratio under 1.00
  `,
  },
  household_income_median: {
    id: "description.household_income_median",
    defaultMessage: `
      Median household income (dollars, inflation-adjusted to data file year)
  `,
  },
  total_population: {
    id: "description.total_population",
    defaultMessage: `Total population (ages 1 and over)`,
  },
  owned_one_or_more_vehicles: {
    id: "description.owned_one_or_more_vehicles",
    defaultMessage: `
      This represents the proportion of households that own at least one passenger 
      car, van, pickup truck or panel truck weighing 2,000 pounds or less that 
      is available for the use of household members. Vehicle ownership is 
      considered a proxy for affluence. The ownership of a personal vehicle 
      increases personal autonomy and access to jobs and services. 
    `,
  },
  unemployed: {
    id: "description.unemployed",
    defaultMessage: `
      Percentage of civilian labor force that is unemployed (ages 16 and over)
    `,
  },

  // education
  gte_bachelor_degree: {
    id: "description.gte_bachelor_degree",
    defaultMessage: `
      A bachelor’s degree indicates that a person has graduated from a
      four-year college or university. The percentage displayed includes those
      who have at least a bachelor’s degree and it includes individuals who
      have achieved higher degrees (e.g., masters, doctorate). Individuals with
      higher levels of education often have higher paying jobs, allowing them
      to have better access to resources and healthcare.
  `,
  },
  lte_high_school: {
    id: "description.lte_high_school",
    defaultMessage: `
      Indicates that a person has an education level equal to a high school degree or less . Individuals with less formal education often have lower paying jobs, allowing them to have less access to resources and healthcare.
    `,
  },

  // environment
  acetaldehyde: {
    id: "description.acetaldehyde",
    defaultMessage: `
      Concentration of Acetaldehyde
    `,
  },
  gas_1_3_butadiene: {
    id: "description.gas_1_3_butadiene",
    defaultMessage: `
      Concentration of 1,3-Butadiene
    `,
  },
  benzene: {
    id: "description.benzene",
    defaultMessage: `
      Concentration of Benzene
    `,
  },
  carbon_tetrachloride: {
    id: "description.carbon_tetrachloride",
    defaultMessage: `
      Concentration of Carbon Tetrachloride
    `,
  },
  diesel_pm: {
    id: "description.diesel_pm",
    defaultMessage: `
      Concentration of Diesel PM
    `,
  },
  ethylbenzene: {
    id: "description.ethylbenzene",
    defaultMessage: `
      Concentration of Ethlbenzene
    `,
  },
  formaldehyde: {
    id: "description.formaldehyde",
    defaultMessage: `
      Concentration of Formaldehyde
    `,
  },
  hexane: {
    id: "description.hexane",
    defaultMessage: `
      Concentration of Hexane
    `,
  },
  lead_compounds: {
    id: "description.lead_compounds",
    defaultMessage: `
      Concentration of Lead Compounds
    `,
  },
  manganese_compounds: {
    id: "description.manganese_compounds",
    defaultMessage: `
      Concentration of Manganese  Compounds
    `,
  },
  mercury_compounds: {
    id: "description.mercury_compounds",
    defaultMessage: `
      Concentration of Mercury Compounds
    `,
  },
  methanol: {
    id: "description.methanol",
    defaultMessage: `
      Concentration of Methanol
    `,
  },
  methyl_chloride: {
    id: "description.methyl_chloride",
    defaultMessage: `
      Concentration of Methyl Chloride
    `,
  },
  nickel_compounds: {
    id: "description.nickel_compounds",
    defaultMessage: `
      Concentration of Nickel Compounds
    `,
  },
  pm25: {
    id: "description.pm25",
    defaultMessage: `
      Estimated annual ground level PM2.5
  `,
  },
  toluene: {
    id: "description.toluene",
    defaultMessage: `
      Concentration of Toluene
    `,
  },
  xylenes: {
    id: "description.xylenes",
    defaultMessage: `
      Concentration of Xylenes
    `,
  },

  // health
  ckd: {
    id: "description.ckd",
    defaultMessage: `
      People with chronic kidney disease have kidneys that are damaged 
      and cannot filter blood properly. Risk factors include diabetes, 
      high blood pressure, heart disease, and family history. The Centers 
      for Disease Control (CDC) uses one definition to identify 
      individuals with chronic kidney disease (CKD) based on the rate 
      at which a person’s kidneys filter urine. A higher glomerular 
      filtration rate (GFR), the better the health of a person’s kidney. 
      The Kidney Disease Improving Global Outcomes (KDIGO) consortium 
      uses a different definition of CKD that is also based on GFR.
      `,
  },

  obesity_total_obesity: {
    id: "description.obesity_total_obesity",
    defaultMessage: `
      Total proportion of people who are obese.
      `,
  },

  diabetes_total: {
    id: "description.diabetes_total",
    defaultMessage: `
      Percentage of patients with diabetes. Patients >=18 years of age at Duke or Lincoln Community Health Center with a Durham County address who had a ICD diagnosis for diabetes. Source: Duke University Health System and Lincoln Community Health Center.
      `,
  },

  hi_medicaid_any: {
    id: "description.hi_medicaid_any",
    defaultMessage: `
      Percentage of population with any Medicaid/means-tested public health insurance coverage
    `,
  },

  hi_medicaid_any_below_64: {
    id: "description.hi_medicaid_any_below_64",
    defaultMessage: `
      Percentage of population with any Medicaid/means-tested public health insurance coverage (ages 64 and below)
    `,
  },
  hi_medicare_only: {
    id: "description.hi_medicare_only",
    defaultMessage: `
      Percentage of population with Medicare only
    `,
  },
  hi_private_employer_direct: {
    id: "description.hi_private_employer_direct",
    defaultMessage: `
      Percentage of population with employer-based and direct-purchase coverage
    `,
  },
  hi_private_any: {
    id: "description.hi_private_any",
    defaultMessage: `
      Percentage of population with any private health insurance coverage
    `,
  },
  hi_private_employer: {
    id: "description.hi_private_employer",
    defaultMessage: `
      Percentage of population with employer-based health insurance
    `,
  },
  hi_private_medicare: {
    id: "description.hi_private_medicare",
    defaultMessage: `
      Percentage of population with employer-based and Medicare insurance coverage
    `,
  },
  hi_private_self_employed: {
    id: "description.hi_private_self_employed",
    defaultMessage: `
      Percentage of population with direct-purchase health insurance only
    `,
  },
  hi_medicare_public_only: {
    id: "description.hi_medicare_public_only",
    defaultMessage: `
      Percentage of population with Medicare, Medicaid, TRICARE/military, U.S. Department of Veterans Affairs (VA) coverage and other public-only health insurance combinations
    `,
  },
  hi_public_other: {
    id: "description.hi_public_other",
    defaultMessage: `
      Percentage of population with other public-only health insurance combinations
    `,
  },
  hi_tricare_va: {
    id: "description.hi_tricare_va",
    defaultMessage: `
      Percentage of population with TRICARE/military or VA health insurance coverage only
    `,
  },
  hi_uninsured: {
    id: "description.hi_uninsured",
    defaultMessage: `
      Percentage of population with no health insurance coverage
    `,
  },

  ckd_cdc_less_30: {
    id: "description.ckd_cdc_less_30",
    defaultMessage: `
      Percent of population with stage 4 chronic kidney disease based on the Centers for Disease Control and Prevention definition
      `,
  },
  ckd_cdc_less_60: {
    id: "description.ckd_cdc_less_60",
    defaultMessage: `
     Percent of population with stage 3 chronic kidney disease based on the Centers for Disease Control and Prevention definition
      `,
  },
  ckd_kdigo_less_20: {
    id: "description.ckd_kdigo_less_20",
    defaultMessage: `
      KDIGO < 20 - 
      `,
  },
  ckd_kdigo_less_30: {
    id: "description.ckd_kdigo_less_30",
    defaultMessage: `
      Percent of population with stage 3b moderately to severely decreased kidney function based on the Kidney Disease Improving Global Outcomes (KDIGO) definition
      `,
  },
  ckd_kdigo_less_60: {
    id: "description.ckd_kdigo_less_60",
    defaultMessage: `
      Percent of population with stage 3a mild to moderately decreased kidney function based on the Kidney Disease Improving Global Outcomes (KDIGO) definition.
      `,
  },
  htn: {
    id: "description.htn",
    defaultMessage: `
    High blood pressure (hypertension) is a common condition in which the 
    long-term force of the blood against your artery walls is high enough 
    that it may eventually cause health problems, such as heart disease.
      `,
  },
  htn_bp: {
    id: "description.htn_bp_htn",
    defaultMessage: `
      Blood transported through the body’s arteries is constantly 
      pushing against the artery walls, which causes blood pressure. 
      High blood pressure occurs when one’s blood pressure is 
      consistently above the normal limit. If an adult’s blood pressure 
      is typically above normal values, then a health care professional 
      may diagnose the patient with high blood pressure. It is important 
      to both prevent and treat high blood pressure as this disease can 
      increase one’s risk of developing other diseases such as heart 
      disease, stroke, chronic kidney disease, and dementia. 
      Source: '<a' href="https://www.cdc.gov/bloodpressure/about.htm"'>'CDC.gov'</a>'
      `,
  },
  htn_diag: {
    id: "description.htn_diag_htn",
    defaultMessage: `
      Hypertension is synonymous with high blood pressure (see above 
        indicator). This represents the number of people who have 
        been diagnosed with hypertension by their health care providers.
      `,
  },
  obesity: {
    id: "description.obesity",
    defaultMessage: `
        Obesity involves having an excessive amount of body fat.
        Obesity increases the risk of heart disease, diabetes,
        high blood pressure and certain cancers. Risk factors 
        for obesity include family history, unhealthy diet, 
        physical inactivity, and certain diseases and medications. 
        Obesity classifications are based on Body Mass Index (BMI) 
        which estimate body fat levels based on height and weight.
      `,
  },
  obesity_class_1: {
    id: "description.obesity_class_1",
    defaultMessage: `BMI 30.0 to 34.9. Class 1 Obesity.
      `,
  },
  obesity_class_2: {
    id: "description.obesity_class_2",
    defaultMessage: `BMI 35.0 to 39.9. Class 2 Obesity.
      `,
  },
  obesity_class_3: {
    id: "description.obesity_class_3",
    defaultMessage: `BMI greater than or equal to 40. Class 3 Obesity.
      `,
  },
  obesity_ow: {
    id: "description.obesity_ow",
    defaultMessage: `
      Not obese but having body fat levels higher than recommended,
      BMI  25.0 to 29.9.
      `,
  },
  obesity_total: {
    id: "description.obesity_total",
    defaultMessage: `
      Total proportion of people who are obese or overweight.
      `,
  },
  lbw: {
    id: "description.lbw",
    defaultMessage: `
      Low birth weight describes babies who are born weighing less than 
      5 pounds, 8 ounces. Babies with low birth weight can be healthy, but 
      they may also have serious health problems including jaundice, trouble 
      gaining weight, higher risk for infection, and nervous system and 
      breathing problems. Very low birth weight is related to cerebral palsy,
      blindness, deafness and developmental delay. Risk factors for 
      low birth weight include premature birth, infection or lack of 
      weight gain during pregnancy, age less than 17 or older than 35 
      during pregnancy, and smoking or alcohol use during pregnancy.
      `,
  },
  lbw_preterm: {
    id: "description.lbw_preterm",
    defaultMessage: `
      Babies are considered pre-term if they are born before 37 weeks into 
      the pregnancy. Pre-term births occur due to a variety of causes 
      including maternal age, high blood pressure, diabetes, and 
      previous pregnancies. Babies born pre-term have an increased 
      risks for health complications, learning disabilities, and neonatal demise.
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'  
    `,
  },
  lbw_late_preterm: {
    id: "description.lbw_late_preterm",
    defaultMessage: `
      Late pre-term birth occurs when babies are born between 32 and 37 weeks into the pregnancy. 
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'
    `,
  },
  lbw_very_preterm: {
    id: "description.lbw_very_preterm",
    defaultMessage: `
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'
    `,
  },
  lbw_extrem_preterm: {
    id: "description.lbw_extrem_preterm",
    defaultMessage: `
      Extremely pre-term birth occurs when babies are born before 28 weeks into the pregnancy. 
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>' 
    `,
  },
  lbw_very_low: {
    id: "description.lbw_very_low",
    defaultMessage: `Very low birth weight is defined as babies born weighing less than 1500 grams.`,
  },
  lbw_low: {
    id: "description.lbw_low",
    defaultMessage: `Low birth weight is defined as babies born weighing less than 2500 grams.`,
  },
  mi: {
    id: "description.mi",
    defaultMessage: `
      A myocardial infarction, also known as a heart attack, happens 
      when one or more areas of the heart muscle don't get enough oxygen.

      A heart attack is a medical emergency.
      `,
  },
  mi_diag: {
    id: "description.mi_diag",
    defaultMessage: `
      Diagnosed with a heart attack. A heart attack occurs when a blood clot blocks blood flow to the heart.
      `,
  },

  stroke: {
    id: "description.stroke",
    defaultMessage: `
      Percent of the population with a diagnosis of stroke. A stroke occurs when a clot blocks blood supply to the brain or a blood vessel in the brain bursts.
      `,
  },
  stroke_diag: {
    id: "description.stroke_diag",
    defaultMessage: `
      Stroke among adults aged >=18 years. A stroke occurs when a clot blocks blood supply to the brain or a blood vessel in the brain bursts. 
      `,
  },

  diabetes: {
    id: "description.diabetes",
    defaultMessage: `
      Diabetes is a chronic (long-lasting) health condition that affects
      how your body turns food into energy.
    `,
  },
  diabetes_diag: {
    id: "description.diabetes_diag",
    defaultMessage: `
      Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address who had a ICD diagnosis for diabetes  
    `,
  },

  diabetes_good_control: {
    id: "description.diabetes_good_control",
    defaultMessage:
      "Number of patients with diabetes within Durham County who had all HbA1c tests in the time period  lower than 8.0%.",
  },
  diabetes_poor_control: {
    id: "description.diabetes_poor_control",
    defaultMessage:
      "# of adult patients with diabetes within Durham County who had at least one HbA1c test higher than 9.0% in 2017",
  },
  diabetes_prediabetes_only: {
    id: "description.diabetes_prediabetes_only",
    defaultMessage:
      "Prevalence (#) of pre-diabetes among patients and also listed in the Diabetes Registry within Durham County",
  },
  diabetes_prediabetes_registry: {
    id: "description.diabetes_prediabetes_registry",
    default: "assd",
  },
  htn_hypertension_blood_pressure: {
    id: "description.htn_hypertension_blood_pressure",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address with 3 instances of systolic BP >140 or diastolic BP >90 within 12 months; exclude hypertension during pregnancy",
  },
  htn_hypertension_diagnosed: {
    id: "description.htn_hypertension_diagnosed",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address with ICD diagnosis of hypertensionl; exclude hypertension during pregnancy",
  },
  mi_myocardial_infarction_total: {
    id: "description.mi_myocardial_infarction_total",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address who had an inpatient ICD diagnosis for myocardial infarction",
  },
  obesity_class_1_obesity: {
    id: "description.obesity_class_1_obesity",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address: BMI>=30 & <35; exclude if patient has pregnancy ICD codes",
  },
  obesity_class_2_obesity: {
    id: "description.obesity_class_2_obesity",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address: BMI>=35 & <40; exclude if patient has pregnancy ICD codes",
  },
  obesity_class_3_obesity: {
    id: "description.obesity_class_3_obesity",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address: BMI>=40; exclude if patient has pregnancy ICD codes",
  },
  obesity_overweight: {
    id: "description.obesity_overweight",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a Durham County address: BMI>=25 & <30; exclude if patient has pregnancy ICD codes",
  },
  stroke_total: {
    id: "description.stroke_total",
    default:
      "Percent of population seen at Duke or Lincoln Community Health Center with a ICD diagnosis of stroke",
  },

  // housing
  gross_rent_median: {
    id: "description.gross_rent_median",
    defaultMessage: `
      Median gross rent (dollars)
   `,
  },
  home_value_median: {
    id: "description.home_value_median",
    defaultMessage: `
      Median home value of owner-occupied housing units (dollars)
    `,
  },
  person_one_more_room: {
    id: "description.person_one_more_room",
    defaultMessage: `
      A measure of overcrowding defined by the number of household inhabitants 
      divided by the number of rooms in a household unit. Overcrowding can act 
      as a proxy for wealth and is an important social determinant of health 
      because it may affect mental health and sleep and can increase the 
      spread of infectious disease.
    `,
  },
  eviction_count: {
    id: "description.eviction_count",
    defaultMessage: `
      The eviction count is the number of renters ordered to leave their homes. 
    `,
  },
  eviction_rate: {
    id: "description.eviction_rate",
    defaultMessage: `
      The eviction rate is the subset of eviction filings that received an eviction 
      judgement in which renters were ordered to leave divided by the number of 
      renter-occupied homes in that area. This only counts a single address who 
      received an eviction judgement.
    `,
  },
  eviction_filings: {
    id: "description.eviction_filings",
    defaultMessage: `
      A count of all eviction cases filed in an area, including multiple cases filed 
      against the same address in the same year. 
    `,
  },
  eviction_filing_rate: {
    id: "description.eviction_filing_rate",
    defaultMessage: `
      The eviction filing rate is the ratio of the number of evictions filed 
      in an area divided by the number of renter-occupied homes in that area.
    `,
  },

  out_of_state_ownership: {
    id: "description.out_of_state_ownership",
    defaultMessage: `
      This number is the percent of properties owned by individuals or companies 
      that are outside of North Carolina. These can be national or international 
      firms or individuals. Some out of state companies use shell LLCs located in 
      Durham as the deeded owner of the property so they are not counted in these data. 
    `,
  },
  cost_burdened_morgage_holders: {
    id: "description.cost_burdened_morgage_holders",
    defaultMessage: `
      A household is cost burdened if they spend more than 30% of their 
      income on housing. A household is severely cost burdened if they 
      spend more than 50% of their income on housing.
      `,
  },
  cost_burdened_renters: {
    id: "description.cost_burdened_renters",
    defaultMessage: `
      Rent burden is defined as spending more than 30 percent of household 
      income on rent. In 2009, more than half of renter-occupied households 
      with children (54 percent) experienced rent burden. Between 2002 and 2009, 
      the proportion of households with children affected by rent burden 
      increased significantly.
      `,
  },

  // indices
  adi: {
    id: "description.adi",
    defaultMessage: `
     Decile of ADI
    `,
  },
  ahrq_ses: {
    id: "description.ahrq_ses",
    defaultMessage: `
      The index is based on a person's block group or census track of residence and includes the following 7 Census variables: 
      percentage of people in the labor force who are unemployed, percentage of people living below poverty level, median household
      income, median value of owner-occupied dwellings, percentage of people ≥25 years of age with less than a 12th-grade education,
      percentage of people ≥25 years of age completing ≥4 years of college, and percentage of households that average ≥1 people per room.
      Click '<a' href="https://www.ahajournals.org/doi/10.1161/circoutcomes.113.000520" target="_blank"'><u>'AHRQ Index'</u></a>'
      to learn more
    `,
  },
  coi: {
    id: "description.coi",
    defaultMessage: `
      The Child Opportunity Index (COI) measures and maps the quality of resources and conditions that matter for 
      children to develop in a healthy way in the neighborhoods where they live.
      Click '<a' href="http://www.diversitydatakids.org/child-opportunity-index"
      target="_blank"'><u>'COI'</u></a>' to learn more.
    `,
  },
  svi: {
    id: "description.svi",
    defaultMessage: `
      National Overall SVI Score. Scores range from 0 (lowest vulnerability) to 1 (highest vulnerability). 
    `,
  },
  hvi: {
    id: "description.hvi",
    defaultMessage:
      "The heat vulnerability index (HVI) combines census tract level information on race, ethnicity, age, education, employment status, poverty, housing, disability, country of origin, diabetes status, vegetation coverage, and air temperature to identify areas and populations at greatest risk from the effects of heat.",
  },

  // infrastructure
  bar_nightclub: {
    id: "description.bar_nightclub",
    defaultMessage: `
      Number of Bars/Nightclubs within a neighborhood. Research suggests that neighborhoods with high concentrations of businesses that serve alcohol can increase rates of crime. This can negatively impact health.
    `,
  },
  bb: {
    id: "description.bb",
    defaultMessage: `Broadband.`,
  },
  bb_any_connection: {
    id: "description.bb_any_connection",
    defaultMessage: `Number of Fixed Residential Broadband Providers, any speed (count).`,
  },
  bb_high_speed: {
    id: "description.bb_high_speed",
    defaultMessage: `Number of Fixed Residential Broadband Providers, High Speed (count).`,
  },
  parks: {
    id: "description.parks",
    defaultMessage: `
      Number of parks within a neighborhood. Parks provide the community with a location to exercise and enjoy the outdoors. Physical Activity and exercise reduce risk for multiple adverse health outcomes. With more resources conveniently available, community members are more likely to go to the parks and engage in physical activity.
    `,
  },
  hospitals: {
    id: "description.hospitals",
    defaultMessage: `
      Hospitals allow individuals to seek healthcare more easily. This in turn
      allows people to manage their health better, resulting in fewer adverse
      health outcomes.
    `,
  },
  impervious_area: {
    id: "description.impervious_area",
    defaultMessage: `
      Percent of neighborhood with impervious surfaces. Impervious surfaces include surfaces that prevent water from draining naturally into the ground. This can include roads, parking lots, sidewalks, and rooftops. Impervious surfaces increase erosion, reduce water quality, and create areas where temperatures are higher than areas with less impervious surfaces (often called “heat islands”). 
    `,
  },
  liquor_stores: {
    id: "description.liquor_stores",
    defaultMessage: `
      Number of liquor stores within a neighborhood. Neighborhoods a greater number of liquor stores can have higher rates of alcohol-related hospitalizations, drunk driving accidents, and pedestrian injuries. They can also increase the number of criminal and nuisance activities. These all impact health. 
   `,
  },
  religious_locations: {
    id: "description.religious_location",
    defaultMessage: `
      Number of religious locations within a neighborhood. Religious institutions often serve as a source of social support for individuals and may serve a positive influence on one's health.
    `,
  },
  tobacco_retail_outlets: {
    id: "description.tobacco_retail_outlets",
    defaultMessage: `
      Number of tobacco retail outlets. Tobacco retail outlets (TROs) are stores that have tobacco and tobacco related products for purchase. Examples of TROs includes gas stations and grocery stores. Tobacco has been proven to cause cancer and lung disease; it also includes the addictive chemical nicotine. The availability of tobacco within different areas of the community can influence the amount of tobacco consumed which will affect the health of individuals who use tobacco products. TROs were identified in Durham County by reviewing existing business records, online resources like Google Maps, other databases, and by walking through neighborhoods. All potential TROs were visited and confirmed to sell tobacco.”
  `,
  },
  tree_coverage: {
    id: "description.tree_coverage",
    defaultMessage: `
      Percent of neighborhood with tree coverage. Urban tree cover provides benefits to human health and well-being, but studies suggest that tree cover is often inequitably distributed.
    `,
  },
  walkability: {
    id: "description.walkability",
    defaultMessage: `
      This is based on the National Walkability Index, which ranks 
      block groups according to their relative walkability on a 
      scale from 1-20, with 1 referring to the least walkability 
      and 20 to the most walkability. Living in walkable neighborhoods 
      encourages physical activity, which can help to regulate body weight 
      and improve overall health.
      `,
  },

  //food access
  lilatracts_1and10: {
    id: "description.lilatracts_1and10",
    defaultMessage: `
    A tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA.gov
    `,
  },
  lilatracts_halfand10: {
    id: "description.lilatracts_halfand10",
    defaultMessage: `A tract with at least 500 people, or 33 percent of the population, living more than one-half mile (urban areas) or 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA.gov`,
  },
  lilatracts_1and20: {
    id: "description.lilatracts_1and20",
    defaultMessage: `A tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA.gov`,
  },
  lilatracts_vehicle: {
    id: "description.lilatracts_vehicle",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA'`,
  },
  la1and10: {
    id: "description.la1and10",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA`,
  },
  lahalfand10: {
    id: "description.lahalfand10",
    defaultMessage: `A low-income tract with at least 500 people, or 33 percent of the population, living more than one-half mile (urban areas) or more than 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA`,
  },
  la1and20: {
    id: "description.la1and20",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA`,
  },
  latractsvehicle_20: {
    id: "description.latractsvehicle_20",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: USDA`,
  },

  et: {
    id: "description.et",
    defaultMessage: `Economy Transportation.`,
  },

  hi: {
    id: "description.hi",
    defaultMessage: `Health Insurance.`,
  },

  //new
  population: {
    id: "description.population",
    defaultMessage: "Percentage of population that is foreign-born",
  },
  rent_median: {
    id: "description.rent_median",
    defaultMessage: "Median gross rent (dollars)",
  },

  et_household_no_vehicle: {
    id: "description.et_household_no_vehicle",
    defaultMessage: "Percentage of housing units with no vehicle available",
  },
  non_institutionalized_population: {
    id: "description.non_institutionalized_population",
    defaultMessage: "Total civilian noninstitutionalized population",
  },

  gini_index: {
    id: "description.gini_index",
    defaultMessage:
      "A summary measure of income inequality. A value of 0 represents perfect equality and a value of 100 represents complete inequality.",
  },

  per_capita_income: {
    id: "description.per_capita_income",
    defaultMessage:
      "Per capita income (dollars, inflation-adjusted to data file year)",
  },
  household_food_stamps: {
    id: "description.household_food_stamps",
    defaultMessage:
      "Percentage of households that received food stamps/SNAP, past 12 months",
  },
  household_public_assistance: {
    id: "description.household_public_assistance",
    defaultMessage:
      "Percentage of households with public assistance income or food stamps/SNAP",
  },
  et_commute_time_15min: {
    id: "description.et_commute_time_15min",
    defaultMessage:
      "Percentage of workers with < 15-minute commute time (ages 16 and over)",
  },
  et_commute_time_29min: {
    id: "description.et_commute_time_29min",
    defaultMessage:
      "Percentage of workers with 15- to 29-minute commute time (ages 16 and over)",
  },
  et_commute_time_59min: {
    id: "description.et_commute_time_59min",
    defaultMessage:
      "Percentage of workers with 30- to 59-minute commute time (ages 16 and over)",
  },
  et_commute_time_60min_up: {
    id: "description.et_commute_time_60min_up",
    defaultMessage:
      "Percentage of workers with at least 60-minute commute time (ages 16 and over)",
  },
  et_drive_to_work: {
    id: "description.et_drive_to_work",
    defaultMessage:
      "Percentage of workers taking a car, truck, or van to work (ages 16 and over)",
  },
  et_public_commute_15min: {
    id: "description.et_public_commute_15min",
    defaultMessage:
      "Percentage of workers who use public transportation with < 15-minute commute time (ages 16 and over)",
  },
  et_public_commute_29min: {
    id: "description.et_public_commute_29min",
    defaultMessage:
      "Percentage of workers who use public transportation with 15- to 29-minute commute time (ages 16 and over)",
  },
  et_public_commute_59min: {
    id: "description.et_public_commute_59min",
    defaultMessage:
      "Percentage of workers who use public transportation with 30- to 59-minute commute time (ages 16 and over)",
  },
  et_public_commute_60min_up: {
    id: "description.et_public_commute_60min_up",
    defaultMessage:
      "Percentage of workers who use public transportation with at least 60-minute commute time (ages 16 and over)",
  },
  et_public_transit: {
    id: "description.et_public_transit",
    defaultMessage:
      "Percentage of workers taking public transportation, excluding taxicab (ages 16 and over)",
  },
  et_walk_to_work: {
    id: "description.et_walk_to_work",
    defaultMessage: "Percentage of workers walking to work (ages 16 and over)",
  },
  et_work_no_car: {
    id: "description.et_work_no_car",
    defaultMessage:
      "Percentage of workers in households with no vehicle available (ages 16 and over)",
  },
  graduate_degree: {
    id: "description.graduate_degree",
    defaultMessage:
      "Percentage of population with a master's or professional school degree or doctorate (ages 25 and over)",
  },

  civilian_labor_force: {
    id: "description.civilian_labor_force",
    defaultMessage:
      "Total population in the civilian labor force (ages 16 and over)",
  },
  civilian_employed_population: {
    id: "description.civilian_employed_population",
    defaultMessage: "Total civilian employed population (ages 16 and over)",
  },
  housing_units: {
    id: "description.housing_units",
    defaultMessage: "Total housing units",
  },
  households: {
    id: "description.households",
    defaultMessage: "Total number of households",
  },
  household_size_average: {
    id: "description.household_size_average",
    defaultMessage: "Average household size",
  },
  year_built_median: {
    id: "description.year_built_median",
    defaultMessage: "Median year structure built of housing units",
  },
  units_10_or_more: {
    id: "description.units_10_or_more",
    defaultMessage: "Percentage of housing in structures with 10 or more units",
  },
  units_built_1979: {
    id: "description.units_built_1979",
    defaultMessage: "Percentage of housing units built before 1979",
  },
  different_state: {
    id: "description.different_state",
    defaultMessage:
      "Percentage of population that moved from different state in the past year (age 1 and over)",
  },
  occupied_house_no_fuel: {
    id: "description.occupied_house_no_fuel",
    defaultMessage: "Percentage of occupied housing units without fuel",
  },
  occupied_house_utility_gas: {
    id: "description.occupied_house_utility_gas",
    defaultMessage:
      "Percentage of occupied housing units with utility gas heating",
  },
  occupied_house_bottled_tank_lp_gas: {
    id: "description.occupied_house_bottled_tank_lp_gas",
    defaultMessage:
      "Percentage of occupied housing units with bottled, tank, or LP gas",
  },
  occupied_house_oil: {
    id: "description.occupied_house_oil",
    defaultMessage:
      "Percentage of occupied housing units with fuel oil heating",
  },
  occupied_house_wood: {
    id: "description.occupied_house_wood",
    defaultMessage: "Percentage of occupied housing units with wood heating",
  },
  occupied_house_coal: {
    id: "description.occupied_house_coal",
    defaultMessage:
      "Percentage of occupied housing units with coal or coke heating",
  },
  occupied_house_other: {
    id: "description.occupied_house_other",
    defaultMessage:
      "Percentage of occupied housing units with other heating fuel",
  },
  occupied_house_electricity: {
    id: "description.occupied_house_electricity",
    defaultMessage:
      "Percentage of occupied housing units with electricity heating",
  },
  occupied_house_solar_energy: {
    id: "description.occupied_house_solar_energy",
    defaultMessage: "Percentage of occupied housing units with solar energy",
  },
  in_county_move_1_year_ago: {
    id: "description.in_county_move_1_year_ago",
    defaultMessage:
      "Percentage of population that moved within the same county in the past year (age 1 and over)",
  },
  in_state_move_1_year_ago: {
    id: "description.in_state_move_1_year_ago",
    defaultMessage:
      "Percentage of population that moved from different county within same state in the past year (age 1 and over)",
  },
  lacking_kitchen_facilities: {
    id: "description.lacking_kitchen_facilities",
    defaultMessage:
      "Percentage of housing units lacking complete kitchen facilities",
  },
  owner_occupied_housing_units: {
    id: "description.owner_occupied_housing_units",
    defaultMessage: "Percentage of occupied housing units: owner-occupied",
  },
  owner_occupied_housing_units_with_children: {
    id: "description.owner_occupied_housing_units_with_children",
    defaultMessage: "Percentage of owner-occupied housing units with children",
  },
  lacking_plumbing_facilities: {
    id: "description.lacking_plumbing_facilities",
    defaultMessage:
      "Percentage of housing units lacking complete plumbing facilities",
  },
  renter_occupied_housing_units: {
    id: "description.renter_occupied_housing_units",
    defaultMessage: "Percentage of occupied housing units: rented",
  },
  vacant_housing_units: {
    id: "description.vacant_housing_units",
    defaultMessage: "Percentage of housing units vacant",
  },
  bb_no_computer_device: {
    id: "description.bb_no_computer_device",
    defaultMessage: "Percentage of households without a computing device",
  },
  bb_with_smartphone: {
    id: "description.bb_with_smartphone",
    defaultMessage: "Percentage of households with a smartphone",
  },
  bb_with_tablet_only: {
    id: "description.bb_with_tablet_only",
    defaultMessage:
      "Percentage of households with a tablet or other portable wireless computer with no other type of computing device",
  },
  bb_with_pc_only: {
    id: "description.bb_with_pc_only",
    defaultMessage:
      "Percentage of households with a desktop or laptop with no other type of computing device",
  },
  bb_with_internet_subscription: {
    id: "description.bb_with_internet_subscription",
    defaultMessage: "Percentage of households with any internet subscription",
  },
  bb_with_internet_no_subscription: {
    id: "description.bb_with_internet_no_subscription",
    defaultMessage:
      "Percentage of households with internet access without a subscription",
  },
  bb_with_broadband: {
    id: "description.bb_with_broadband",
    defaultMessage:
      "Percentage of households with broadband such as cable, fiber optic or DSL",
  },
  bb_with_broadband_no_internet_subscription: {
    id: "description.bb_with_broadband_no_internet_subscription",
    defaultMessage:
      "Percentage of households with broadband such as cable, fiber optic or DSL with no other type of Internet subscription",
  },
  bb_with_broadband_any: {
    id: "description.bb_with_broadband_any",
    defaultMessage: "Percentage of households with broadband of any type",
  },
  bb_no_internet_access: {
    id: "description.bb_no_internet_access",
    defaultMessage: "Percentage of households with no internet access",
  },
  cdc: {
    id: "description.cdc",
    defaultMessage: `500 Cities Project: Provided city and census tract level estimates for chronic disease risk factors, health outcomes, and clinical preventive services for the largest 500 cities in the United States from 2016-2019. The project included a total population of over 100 million people, representing 33% of the US population. More information can be found at&nbsp; Click '<a' href=\"https://www.cdc.gov/places/about/500-cities-2016-2019/index.html\" target=\"_blank\"'><u>'CDC'</u></a>'
      
    PLACES: The PLACES Project replaced the 500 Cities Project on December 2020 and expanded estimates on chronic disease risk factors, health outcomes, and clinical preventive services to the entire country`,
  },
  cdc_teeth_lost: {
    id: "description.cdc_teeth_lost",
    defaultMessage: "Percent of adults aged >=65 years with all teeth lost",
  },
  cdc_arthritis: {
    id: "description.cdc_arthritis",
    defaultMessage: "Percent of adults aged >=18 years with arthritis",
  },
  cdc_bingedrink: {
    id: "description.cdc_bingedrink",
    defaultMessage: "Percent of adults aged >=18 years that binge drink",
  },
  cdc_cancer: {
    id: "description.cdc_cancer",
    defaultMessage:
      "Percent of adults aged >=18 years with cancer (excluding skin cancer)",
  },
  cdc_cervical: {
    id: "description.cdc_cervical",
    defaultMessage:
      "Percent of adult women aged 21-65 years with cervical cancer screening",
  },
  cdc_cholesterol: {
    id: "description.cdc_cholesterol",
    defaultMessage:
      "Percent of adults aged >=18 years with cholesterol screening",
  },
  cdc_kidney: {
    id: "description.cdc_kidney",
    defaultMessage:
      "Percent of adults aged >=18 years with chronic kidney disease",
  },
  cdc_pulmonary: {
    id: "description.cdc_pulmonary",
    defaultMessage:
      "Percent of adults aged >=18 years with chronic obstructive pulmonary disease",
  },
  cdc_heart: {
    id: "description.cdc_heart",
    defaultMessage:
      "Percent of adults aged >=18 years with coronary heart disease",
  },
  cdc_asthma: {
    id: "description.cdc_asthma",
    defaultMessage: "Percent of adults aged >=18 years with asthma",
  },
  cdc_insurance: {
    id: "description.cdc_insurance",
    defaultMessage:
      "Percent of adults aged 18-64 years that currently lack health insurance",
  },
  cdc_smoking: {
    id: "description.cdc_smoking",
    defaultMessage:
      "Percent of adults aged >=18 years that are currently smoking",
  },
  cdc_depression: {
    id: "description.cdc_depression",
    defaultMessage: "Percent of adults aged >=18 years with depression",
  },
  cdc_diabetes: {
    id: "description.cdc_diabetes",
    defaultMessage: "Percent of adults aged >=18 years diagnosed with diabetes",
  },
  cdc_health: {
    id: "description.cdc_health",
    defaultMessage:
      "Percent of adults aged >=18 years with fair or poor self-rated health status",
  },
  cdc_fecal: {
    id: "description.cdc_fecal",
    defaultMessage:
      "Percent of adults aged 50-75 years with fecal occult blood test, sigmoidoscopy, or colonoscopy",
  },
  cdc_bphigh: {
    id: "description.cdc_bphigh",
    defaultMessage:
      "Percent of adults aged >=18 years with high blood pressure",
  },
  cdc_cholesterol_high: {
    id: "description.cdc_cholesterol_high",
    defaultMessage:
      "Percent of adults aged >=18 years who have been screened in the past 5 years with high cholesterol",
  },
  cdc_mammography: {
    id: "description.cdc_mammography",
    defaultMessage: "Percent of women aged 50-74 years with mammography use",
  },
  cdc_mental: {
    id: "description.cdc_mental",
    defaultMessage:
      "Percent of adults aged >=18 years with mental health not good for >=14 days",
  },
  cdc_leisure: {
    id: "description.cdc_leisure",
    defaultMessage:
      "Percent of adults aged >=18 years with no leisure-time physical activity",
  },
  cdc_obesity: {
    id: "description.cdc_obesity",
    defaultMessage: "Percent of adults aged >=18 years with obesity",
  },
  cdc_preventive_m: {
    id: "description.cdc_preventive_m",
    defaultMessage:
      "Percent of older adult men aged >=65 years who are up to date on a core set of clinical preventive services: Flu shot past year, PPV shot ever, Colorectal cancer screening",
  },
  cdc_preventive_f: {
    id: "description.cdc_preventive_f",
    defaultMessage:
      "Percent of older adult women aged >=65 years who are up to date on a core set of clinical preventive services: Flu shot past year, PPV shot ever, Colorectal cancer screening, and Mammogram past 2 years",
  },
  cdc_physical: {
    id: "description.cdc_physical",
    defaultMessage:
      "Percent of adults aged >=18 years with physical health not good for >=14 days",
  },
  cdc_sleep: {
    id: "description.cdc_sleep",
    defaultMessage:
      "Percent of adults aged >=18 years sleeping less than 7 hours",
  },
  cdc_strokes: {
    id: "description.cdc_strokes",
    defaultMessage: "Percent of adults aged >=18 years with stroke",
  },
  cdc_medicine_bp: {
    id: "description.cdc_medicine_bp",
    defaultMessage:
      "Percent of adults aged >=18 years with high blood pressure taking medicine for high blood pressure control",
  },
  cdc_dentist: {
    id: "description.cdc_dentist",
    defaultMessage:
      "Percent of adults aged >=18 years with visits to dentist or dental clinic",
  },
  cdc_doctor: {
    id: "description.cdc_doctor",
    defaultMessage:
      "Percent of adults aged >=18 years with visits to doctor for routine checkup within the past year",
  },
});

export default useDescription;
