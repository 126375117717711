export const REMOVED_STATISTICS = [
  "Socioeconomic Status (AHRQ)",
  "Low Birth Weight",
  "Extremely Pre-term Birth",
  "Late Pre-term Birth",
  "Pre-term Birth",
  "Very Low Birth Weight",
  "Very Pre-term Birth",
  "Population",
  "Age Greater Than 1",
];
